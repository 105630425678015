<template>
  <div>
    <a-row :gutter="20">
      <a-col :lg="{ span: 5 }">
        <a-card>
          <div class="countcart">
            <div>
              <a-icon type="area-chart" />
            </div>
            <div>
              <span>All User</span>
              <h1>{{ data.alluser }}</h1>
            </div>
          </div>
        </a-card>
      </a-col>

      <a-col :lg="{ span: 5 }">
        <a-card>
          <div class="countcart">
            <div>
              <a-icon type="dot-chart" />
            </div>
            <div>
              <span>Today</span>
              <h1>{{ data.registertoday }}</h1>
            </div>
          </div>
        </a-card>
      </a-col>

      <a-col :lg="{ span: 5 }">
        <a-card>
          <div class="countcart">
            <div>
              <a-icon type="line-chart" />
            </div>
            <div>
              <span>Present</span>
              <h1>{{ data.active }}</h1>
            </div>
          </div>
        </a-card>
      </a-col>

      <a-col :lg="{ span: 5 }">
        <a-card>
          <div class="countcart">
            <div>
              <a-icon type="bar-chart" />
            </div>
            <div>
              <span>Not Present</span>
              <h1>{{ data.inactive }}</h1>
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {},
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$store.dispatch("Dashboard").then((response) => {
        this.data = response.data;
      });
    },
  },
};
</script>
